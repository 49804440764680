import React from 'react'

const Test = (props) => {
  return (
    <div>
      Test | component: {props.component} | path: {props.path}
    </div>
  )
}

export default Test
